import { css, styled } from '@mui/material/styles';
import IconButton from 'components/@shared/IconButton';
import preventForwardProps from 'utils/preventForwardProps';
import PhoneInput from 'components/@shared/PhoneInput';
import Input from 'components/@shared/Input';

export const StyledPhoneInput = styled(
  PhoneInput,
  preventForwardProps(['isChanged']),
)<{ isChanged: boolean }>(
  ({ isChanged }) => css`
    flex: ${isChanged ? 'calc(100% - 56px)' : '100%'} 0 0;

    input {
      height: 48px !important;
    }

    transition: all 0.3s;
  `,
);

export const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ theme, show }) => css`
    width: ${show ? '48px' : '0px'};
    height: ${show ? '48px' : '0px'};
    margin-left: ${show ? '8px' : '0px'};
    background: ${theme.palette.accent.main};
    opacity: ${show ? 1 : 0};

    &:hover {
      background-color: ${theme.palette.accent.light};
    }

    &:active {
      background-color: ${theme.palette.accent.dark};
    }

    &.Mui-disabled {
      background-color: ${theme.palette.primary.dark2};
    }

    svg {
      width: ${show ? '24px' : '0px'};
      height: ${show ? '24px' : '0px'};
      color: ${theme.palette.primary.light};

      transition: all 0.3s;
    }

    transition: all 0.3s;
  `,
);

export const StyledOTPInput = styled(Input)`
  width: 24%;
  aspect-ratio: 1 / 1;
  text-align: center;

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-width: 2px !important;
    }
  }

  & .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 2px !important;
    }
  }

  .MuiInputBase-root {
    height: 100%;
  }

  .Mui-focused {
    &.MuiOutlinedInput-notchedOutline {
      border-width: 6px;
    }
  }

  .MuiInputBase-input {
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`;
