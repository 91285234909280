import { useState } from 'react';
import { PhoneNumberVerificationProps } from '../components/@common/PhoneNumberVerification';
import useMutation from './useMutation';
import { SET_PHONE_NUMBER, VERIFY_PHONE_NUMBER } from 'api/requests/phoneNumberVerification';
import { useAppState } from '../contexts/app';
import { setErrorBanner } from '../contexts/app/actions';
import { useTranslation } from 'react-i18next';

const useValidatePhoneNumber = (
  onSuccessValidation: (phoneNumber: string) => void,
): Pick<PhoneNumberVerificationProps, 'onSendCode' | 'onValidate'> => {
  const { t } = useTranslation();

  const { dispatch } = useAppState();

  const [setPhoneNumber] = useMutation<
    { verificationSkipped: boolean },
    {
      phoneNumber: string;
    }
  >(SET_PHONE_NUMBER, { withErrorBanner: false });
  const [verifyPhoneNumber] = useMutation<
    { isVerificationSuccessful: boolean },
    {
      verificationCode: string;
    }
  >(VERIFY_PHONE_NUMBER, { withErrorBanner: false });
  const [validatedPhoneNumber, setValidatedPhoneNumber] = useState('');

  const onSendCode = async (phoneNumber: string) => {
    try {
      setValidatedPhoneNumber(phoneNumber);
      const response = await setPhoneNumber({ variables: { phoneNumber: phoneNumber.replace('+', '') } });

      if (response.data?.verificationSkipped) {
        onSuccessValidation(validatedPhoneNumber);
        return false;
      }
      return true;
    } catch (error) {
      dispatch(setErrorBanner(t('phoneNumberVerification.incorrectNumberErrorText')));
      return false;
    }
  };

  const onValidate = async (verificationCode: string) => {
    try {
      await verifyPhoneNumber({ variables: { verificationCode } });
      onSuccessValidation(validatedPhoneNumber);
      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  return { onSendCode, onValidate };
};

export default useValidatePhoneNumber;
