import React from 'react';
import { Typography } from '@mui/material';
import { CategoryEnum } from 'constatns';
import useCategories from 'hooks/useCategories';
import { StyledContainer } from './styled';

type Props = {
  category: CategoryEnum;
  variant?: 'white' | 'grey' | 'transparent';
  className?: string;
};

const CategoryLabel: React.FC<Props> = ({ category, variant = 'white', className }) => {
  const categories = useCategories();
  const categoryData = categories[category];

  return (
    <StyledContainer variant={variant} className={className}>
      <img width="24px" height="24px" src={categoryData.icon} alt={categoryData.localizedName} />
      <Typography variant="subtitle1">{categoryData.localizedName}</Typography>
    </StyledContainer>
  );
};

export default CategoryLabel;
