import { useEffect } from 'react';
import useMutation from 'hooks/useMutation';
import { setCompany } from 'contexts/company/actions';
import { useCompanyState } from 'contexts/company';
import { UPDATE_COMPANY } from 'api/requests/company';
import { Company, ManageCompany, Schedule } from 'types';

import { CompanyFormValues } from './index';

type Props = {
  companyFormValues?: CompanyFormValues;
  schedule?: Schedule;
};

const useManageCompany = () => {
  const {
    state: { company },
    dispatch,
  } = useCompanyState();

  const [updateCompany, { loading: updateCompanyLoading, data: updateCompanyData }] = useMutation<
    { updateCompany: Company },
    { input: ManageCompany }
  >(UPDATE_COMPANY);

  useEffect(() => {
    if (updateCompanyData?.updateCompany) {
      dispatch(setCompany(updateCompanyData?.updateCompany));
    }
  }, [dispatch, updateCompanyData]);

  const manageCompany = async ({ companyFormValues, schedule = company.schedule }: Props = {}) => {
    const sanitizedMaster = {
      id: company.id,
      name: companyFormValues ? companyFormValues.name : company.name,
      contactName: companyFormValues ? companyFormValues.contactName : company.contactName,
      email: companyFormValues ? companyFormValues.email : company.email,
      phone: company.phone,
      about: companyFormValues ? companyFormValues.about : company.about,
      instagramLink: companyFormValues ? companyFormValues.instagramLink : company.instagramLink,
      telegramLink: companyFormValues ? companyFormValues.telegramLink : company.telegramLink,
      facebookLink: companyFormValues ? companyFormValues.facebookLink : company.facebookLink,
      townId: companyFormValues ? companyFormValues.city?.id : company.town?.id,
      address: (companyFormValues ? companyFormValues.address : company.address) ?? undefined,
      schedule,
    };

    await updateCompany({ variables: { input: sanitizedMaster } });
  };

  return {
    manageCompany,
    loading: updateCompanyLoading,
  };
};

export default useManageCompany;
