import { gql } from '@apollo/client';

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInputDtoInput!) {
    updateClient(input: $input) {
      ...OutputClientDtoFragment
    }
  }
`;

export const GET_CLIENT = gql`
  query Client {
    client {
      ...OutputClientDtoFragment
    }
  }
`;

export const SET_CLIENT_PHOTO = gql`
  mutation SetClientPhoto($photo: Upload!) {
    setClientPhoto(photo: $photo) {
      ...OutputClientDtoFragment
    }
  }
`;

export const CLIENT_RESERVATIONS = gql`
  query ClientReservations($stauses: [ReservationStatus!]) {
    clientReservations(statuses: $stauses) {
      ...OutputReservationDtoFragment
    }
  }
`;
