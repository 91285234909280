import React from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import Button from 'components/@shared/Button';
import { AccountTypeEnum } from 'constatns';

type Props = {
  onRegister: (accountType: AccountTypeEnum) => void;
};

const TitleSection: React.FC<Props> = ({ onRegister }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container>
      <Stack margin={upMedium ? '40px auto' : '20px auto'} maxWidth="1040px" gap={upMedium ? '32px' : '16px'}>
        <Typography align="center" variant={upMedium ? 'h1' : 'h3'}>
          {t('forCompaniesPage.title')}
        </Typography>
        <Stack marginX="auto" maxWidth="600px">
          <Typography align="center" variant={upMedium ? 'body1' : 'body2'}>
            {t('forCompaniesPage.description')}
          </Typography>
        </Stack>
        <Stack
          marginX={upMedium ? 'auto' : 'none'}
          direction={upMedium ? 'row' : 'column'}
          gap={upMedium ? '32px' : '16px'}
        >
          <Button fullWidth variant="contained" onClick={() => onRegister(AccountTypeEnum.Company)}>
            {t('forCompaniesPage.registerAsCompanyButtonLabel')}
          </Button>
          <Button fullWidth variant="outlined" onClick={() => onRegister(AccountTypeEnum.Company)}>
            {t('forCompaniesPage.registerAsMasterButtonLabel')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default TitleSection;
