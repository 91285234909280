import React, { useEffect, useState } from 'react';
import { FormHelperText, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';

import { StyledIconButton, StyledPhoneInput } from './styled';
import OTPVerification from './OTPVerificationModal';
import CircularProgress from 'components/@shared/CircularProgress';

export type PhoneNumberVerificationProps = {
  error?: string;
  value?: string;
  onSendCode: (phone: string) => Promise<boolean>;
  onValidate: (code: string) => Promise<boolean>;
};

const MIN_PHONE_LENGTH = 7;

const PhoneNumberVerification: React.FC<PhoneNumberVerificationProps> = ({
  value = '',
  error,
  onSendCode,
  onValidate,
}) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState(value);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPhone(value);
  }, [value]);

  const handleChange = (value: string): void => {
    setPhone(value);
  };

  const handleCheckButtonClick = async () => {
    setLoading(true);

    const codeSent = await onSendCode(phone);
    if (codeSent) {
      setOpenModal(true);
    }
    setLoading(false);
  };

  const isChanged = phone?.length >= MIN_PHONE_LENGTH && phone !== value;

  return (
    <ContainerWithBorder gap="12px" error={!!error}>
      <Typography variant="h6">{t('phoneNumberVerification.title')}</Typography>
      <Stack direction="row" alignItems="center">
        <StyledPhoneInput name="phoneNumber" label="" isChanged={isChanged} value={phone} onChange={handleChange} />
        <StyledIconButton disabled={loading} show={isChanged} onClick={handleCheckButtonClick}>
          {loading ? <CircularProgress size={24} /> : <CheckIcon />}
        </StyledIconButton>
      </Stack>
      {!!error && (
        <FormHelperText error>
          <Typography variant="body3">{error}</Typography>
        </FormHelperText>
      )}
      <OTPVerification
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={onValidate}
        sendCode={() => onSendCode(phone)}
      />
    </ContainerWithBorder>
  );
};

export default PhoneNumberVerification;
