import { gql } from '@apollo/client';

export const SET_PHONE_NUMBER = gql`
  mutation SetPhoneNumber($phoneNumber: String!) {
    setPhoneNumber(phoneNumber: $phoneNumber) {
      verificationSkipped
    }
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  mutation VerifyPhoneNumber($verificationCode: String!) {
    verifyPhoneNumber(verificationCode: $verificationCode) {
      isVerificationSuccessful
    }
  }
`;
