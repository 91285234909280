import React, { useEffect } from 'react';
import { Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import Container from 'components/@shared/Container';
import {
  ExampleCard,
  StyledAttributesContainer,
  StyledCategoryLabel,
  StyledExample1Image,
  StyledExample2Image,
  StyledExample3Image,
  StyledExamplesBg,
  StyledExamplesContainer,
  StyledExampleTitle,
  StyledMaster1Image,
  StyledMaster2Image,
  StyledMasterContainer,
  StyledSubcategory,
} from './styled';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/open.svg';
import { CategoryEnum } from 'constatns';
import { useTranslation } from 'react-i18next';
import InlineList from '../@shared/InlineList';

const ServicesExamplesSection = () => {
  const { t } = useTranslation();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (isSmallScreen && containerRef.current) {
      const container = containerRef.current;
      const middleChild = container.children[1] as HTMLDivElement;
      const middleChildPosition = middleChild.offsetLeft - container.offsetWidth / 2 + middleChild.offsetWidth / 2;

      container.scrollTo({ left: Math.max(0, middleChildPosition), behavior: 'smooth' });
    }
  }, [isSmallScreen]);

  return (
    <Stack position="relative" height={isSmallScreen ? '400px' : '532px'} width="100%">
      <Container>
        <StyledExamplesBg />
      </Container>
      <Stack>
        <StyledExamplesContainer ref={containerRef}>
          <ExampleCard>
            <Stack direction="row" justifyContent="space-between" mb={isSmallScreen ? '6px' : '10px'}>
              <StyledCategoryLabel category={CategoryEnum.Medicine} variant="grey" />
              <ShareIcon height={isSmallScreen ? '17px' : '26px'} />
            </Stack>
            <StyledExampleTitle>{t('forCompaniesPage.example1title')}</StyledExampleTitle>
            <StyledExample1Image />
            <Stack mt={isSmallScreen ? '10px' : '14px'} gap={isSmallScreen ? '4px' : '6px'}>
              <StyledAttributesContainer>
                <InlineList
                  fontSize={isSmallScreen ? '6px' : '10px'}
                  lineHeight={isSmallScreen ? '6px' : '10px'}
                  list={[
                    {
                      item: t('forCompaniesPage.example1attribute1'),
                      key: t('forCompaniesPage.example1attribute1'),
                    },
                    {
                      item: t('forCompaniesPage.example1attribute2'),
                      key: t('forCompaniesPage.example1attribute2'),
                    },
                  ]}
                />
              </StyledAttributesContainer>
              <StyledAttributesContainer>
                <InlineList
                  fontSize={isSmallScreen ? '6px' : '10px'}
                  lineHeight={isSmallScreen ? '6px' : '10px'}
                  list={[
                    {
                      item: t('forCompaniesPage.example1attribute3'),
                      key: t('forCompaniesPage.example1attribute3'),
                    },
                    {
                      item: t('forCompaniesPage.example1attribute4'),
                      key: t('forCompaniesPage.example1attribute4'),
                    },
                  ]}
                />
              </StyledAttributesContainer>
            </Stack>
          </ExampleCard>
          <ExampleCard>
            <Stack direction="row" justifyContent="space-between" mb={isSmallScreen ? '6px' : '10px'}>
              <StyledCategoryLabel category={CategoryEnum.Beauty} variant="grey" />
              <ShareIcon height={isSmallScreen ? '17px' : '26px'} />
            </Stack>
            <StyledExampleTitle>{t('forCompaniesPage.example2title')}</StyledExampleTitle>
            <StyledExample2Image />
            <Stack mt={isSmallScreen ? '10px' : '14px'} gap={isSmallScreen ? '12px' : '14px'}>
              <Stack direction="row" gap="3px">
                <StyledSubcategory active>{t('forCompaniesPage.example2subcategory1')}</StyledSubcategory>
                {[
                  t('forCompaniesPage.example2subcategory2'),
                  t('forCompaniesPage.example2subcategory3'),
                  t('forCompaniesPage.example2subcategory4'),
                  t('forCompaniesPage.example2subcategory5'),
                ].map(item => (
                  <StyledSubcategory key={item}>{item}</StyledSubcategory>
                ))}
              </Stack>
              <StyledMasterContainer>
                <StyledMaster1Image />
                <Stack flex="1 1 100%" gap={isSmallScreen ? '2px' : '3px'}>
                  <Typography
                    fontWeight="700"
                    fontSize={isSmallScreen ? '7px' : '11px'}
                    lineHeight={isSmallScreen ? '9px' : '14px'}
                  >
                    {t('forCompaniesPage.example2Master1Name')}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? '6px' : '8px'} lineHeight={isSmallScreen ? '8px' : '12px'}>
                    {t('forCompaniesPage.example2Master1Experience')}
                  </Typography>
                </Stack>
                <OpenIcon />
              </StyledMasterContainer>
              <StyledMasterContainer>
                <StyledMaster2Image />
                <Stack flex="1 1 100%" gap={isSmallScreen ? '2px' : '3px'}>
                  <Typography
                    fontWeight="700"
                    fontSize={isSmallScreen ? '7px' : '11px'}
                    lineHeight={isSmallScreen ? '9px' : '14px'}
                  >
                    {t('forCompaniesPage.example2Master2Name')}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? '6px' : '8px'} lineHeight={isSmallScreen ? '8px' : '12px'}>
                    {t('forCompaniesPage.example2Master2Experience')}
                  </Typography>
                </Stack>
                <OpenIcon />
              </StyledMasterContainer>
            </Stack>
          </ExampleCard>
          <ExampleCard>
            <Stack direction="row" justifyContent="space-between" mb={isSmallScreen ? '6px' : '10px'}>
              <StyledCategoryLabel category={CategoryEnum.Learning} variant="grey" />
              <ShareIcon height={isSmallScreen ? '17px' : '26px'} />
            </Stack>
            <StyledExampleTitle>{t('forCompaniesPage.example3title')}</StyledExampleTitle>
            <StyledExample3Image />
            <Stack mt={isSmallScreen ? '10px' : '14px'} gap={isSmallScreen ? '4px' : '6px'}>
              <StyledAttributesContainer>
                <InlineList
                  fontSize={isSmallScreen ? '6px' : '10px'}
                  lineHeight={isSmallScreen ? '6px' : '10px'}
                  list={[
                    {
                      item: t('forCompaniesPage.example3attribute1'),
                      key: t('forCompaniesPage.example3attribute1'),
                    },
                    {
                      item: t('forCompaniesPage.example3attribute2'),
                      key: t('forCompaniesPage.example3attribute2'),
                    },
                  ]}
                />
              </StyledAttributesContainer>
              <StyledAttributesContainer>
                <InlineList
                  fontSize={isSmallScreen ? '6px' : '10px'}
                  lineHeight={isSmallScreen ? '6px' : '10px'}
                  list={[
                    {
                      item: t('forCompaniesPage.example3attribute3'),
                      key: t('forCompaniesPage.example3attribute3'),
                    },
                    {
                      item: t('forCompaniesPage.example3attribute4'),
                      key: t('forCompaniesPage.example3attribute4'),
                    },
                  ]}
                />
              </StyledAttributesContainer>
            </Stack>
          </ExampleCard>
          ; ;
        </StyledExamplesContainer>
      </Stack>
    </Stack>
  );
};

export default ServicesExamplesSection;
