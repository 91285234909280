import { useEffect } from 'react';
import useMutation from 'hooks/useMutation';
import { useMasterState } from 'contexts/master';
import { setMaster } from 'contexts/master/actions';
import { CREATE_MASTER, UPDATE_MASTER } from 'api/requests/master';
import { prepareCategoriesData } from 'utils/prepareCategoriesData';
import { Master, ManageMaster, InputCategoryDto, Schedule } from 'types';

import { useMatch, useNavigate } from 'react-router';
import { MasterFormValues } from './index';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';

type Props = {
  masterFormValues?: MasterFormValues;
  categories?: InputCategoryDto[];
  schedule?: Schedule;
};

const useManageMaster = () => {
  const {
    state: { master },
    dispatch,
  } = useMasterState();
  const navigate = useNavigate();
  const COMPANY_CABINET_CREATE_MASTER_INFO_TAB = useMatch(PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB);

  const [createMaster, { loading: createMasterLoading, data: createMasterData }] = useMutation<
    { createMaster: Master },
    { input: ManageMaster }
  >(CREATE_MASTER);
  const [updateMaster, { loading: updateMasterLoading, data: updateMasterData }] = useMutation<
    { updateMaster: Master },
    { input: ManageMaster }
  >(UPDATE_MASTER);

  useEffect(() => {
    if (createMasterData?.createMaster) {
      dispatch(setMaster(createMasterData?.createMaster));
    }
  }, [createMasterData, dispatch]);

  useEffect(() => {
    if (updateMasterData?.updateMaster) {
      dispatch(setMaster(updateMasterData?.updateMaster));
    }
  }, [dispatch, updateMasterData]);

  const manageMaster = async ({
    masterFormValues,
    categories = master.categories,
    schedule = master.schedule,
  }: Props = {}) => {
    let phone: string = '';
    if (masterFormValues?.phoneNumber === '+') {
      phone = '';
    } else if (masterFormValues?.phoneNumber) {
      phone = masterFormValues.phoneNumber;
    } else if (master.phone) {
      phone = master.phone;
    }

    const sanitizedMaster = {
      id: master.id,
      contactName: masterFormValues ? masterFormValues.contactName : master.contactName,
      email: masterFormValues ? masterFormValues.email : master.email,
      // eslint-disable-next-line no-nested-ternary
      phone,
      about: masterFormValues ? masterFormValues.about : master.about,
      instagramLink: masterFormValues ? masterFormValues.instagramLink : master.instagramLink,
      telegramLink: masterFormValues ? masterFormValues.telegramLink : master.telegramLink,
      facebookLink: masterFormValues ? masterFormValues.facebookLink : master.facebookLink,
      townId: masterFormValues ? masterFormValues.city?.id : master.town?.id,
      address: (masterFormValues ? masterFormValues.address : master.address) ?? undefined,
      schedule,
      categories: prepareCategoriesData(categories),
    };

    if (master?.id) {
      await updateMaster({ variables: { input: sanitizedMaster } });
    } else {
      await createMaster({ variables: { input: sanitizedMaster } }).then(({ data }) => {
        if (COMPANY_CABINET_CREATE_MASTER_INFO_TAB && data?.createMaster.id) {
          navigate(getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_INFO_TAB, { id: data?.createMaster.id.toString() }));
        }
      });
    }
  };

  return {
    manageMaster,
    loading: createMasterLoading || updateMasterLoading,
  };
};

export default useManageMaster;
