import { gql } from '@apollo/client';

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyDtoInput!) {
    updateCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const SET_COMPANY_SLUG = gql`
  mutation SetCompanySlug($slug: String!) {
    setCompanySlug(slug: $slug) {
      ...CompanyFragment
    }
  }
`;

export const ACTIVATE_COMPANY = gql`
  mutation ActivateCompany($companyId: Long!) {
    activateCompany(companyId: $companyId) {
      ...CompanyFragment
    }
  }
`;

export const DEACTIVATE_COMPANY = gql`
  mutation DeactivateCompany($companyId: Long!) {
    deactivateCompany(companyId: $companyId) {
      ...CompanyFragment
    }
  }
`;

export const GET_COMPANY = gql`
  query Company {
    company {
      ...CompanyFragment
    }
  }
`;

export const SET_COMPANY_PROFILE_PHOTO = gql`
  mutation SetCompanyProfilePhoto($companyId: Long, $photo: Upload!) {
    setCompanyProfilePhoto(companyId: $companyId, photo: $photo) {
      ...CompanyFragment
    }
  }
`;

export const GET_COMPANY_MASTERS = gql`
  query CompanyMasters {
    companyMasters {
      ...MasterListItemFragment
    }
  }
`;

export const COMPANY_MASTER_PAGE = gql`
  query CompanyMasterPage($companyId: Long, $masterId: Long) {
    companyMasterPage(companyId: $companyId, masterId: $masterId) {
      ...OutputServiceGroupDtoFragment
    }
  }
`;

export const COMPANY_RESERVATIONS = gql`
  query CompanyReservations($from: Date!, $to: Date!, $statuses: [ReservationStatus!]) {
    companyReservations(from: $from, to: $to, statuses: $statuses) {
      ...OutputCompanyReservationDtoFragment
    }
  }
`;

export const SEARCH_COMPANY_RESERVATIONS = gql`
  query SearchCompanyReservations(
    $statuses: [ReservationStatus!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    searchCompanyReservations(statuses: $statuses, first: $first, after: $after, last: $last, before: $before) {
      ...SearchCompanyReservationsConnectionFragment
    }
  }
`;

export const APPROVE_COMPANY_RESERVATION = gql`
  mutation ApproveCompanyReservation($reservationId: Long!) {
    approveCompanyReservation(reservationId: $reservationId) {
      ...OutputCompanyReservationDtoFragment
    }
  }
`;

export const CANCEL_COMPANY_RESERVATION = gql`
  mutation CancelCompanyReservation($reservationId: Long!) {
    cancelCompanyReservation(reservationId: $reservationId) {
      ...OutputCompanyReservationDtoFragment
    }
  }
`;
