import React from 'react';

import useMutation from 'hooks/useMutation';
import { useClientState } from 'contexts/client';
import { setClient } from 'contexts/client/actions';
import { SET_CLIENT_PHOTO, UPDATE_CLIENT } from 'api/requests/client';
import { OutputClientDto, UpdateClientInputDtoInput } from 'types/client';

import { ClientFormValues } from './index';

const useInfoTab = () => {
  const {
    state: { client },
    dispatch,
  } = useClientState();

  const [setClientPhoto] = useMutation<{ setClientPhoto: OutputClientDto }, { clientId?: number; photo: File }>(
    SET_CLIENT_PHOTO,
  );

  const [updateClient, { loading: updateClientLoading }] = useMutation<
    {
      updateClient: OutputClientDto;
    },
    { input: UpdateClientInputDtoInput }
  >(UPDATE_CLIENT);

  const onSaveChanges = async (clientFormValues: ClientFormValues) => {
    try {
      if (!client) return;

      const response = await updateClient({
        variables: {
          input: {
            contactName: clientFormValues.contactName,
          },
        },
      });

      if (response.data?.updateClient) {
        dispatch(setClient(response.data?.updateClient));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleImgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file === undefined) return;
    setClientPhoto({ variables: { photo: file } })
      .then(res => {
        if (res.data) {
          dispatch(setClient(res.data.setClientPhoto));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const setClientPhoneNumber = (phone: string) => {
    if (!client) return;
    dispatch(setClient({ ...client, phone }));
  };

  return {
    client,
    manageClientLoading: updateClientLoading,
    onSaveChanges,
    handleImgInputChange,
    setClientPhoneNumber,
  };
};

export default useInfoTab;
