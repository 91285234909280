import { css, styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CategoryLabel from 'components/@common/CategoryLabel';
import example1Img from 'assets/images/example-1.png';
import example2Img from 'assets/images/example-2.png';
import example3Img from 'assets/images/example-3.png';
import background from 'assets/images/background.svg';
import master1 from 'assets/images/master1.png';
import master2 from 'assets/images/master2.png';
import preventForwardProps from 'utils/preventForwardProps';
import Container from 'components/@shared/Container';

export const StyledExamplesBg = styled('div')(
  ({ theme }) => css`
    height: 362px;
    width: 100%;
    background: url(${background});
    border-radius: 40px;

    ${theme.breakpoints.down('md')} {
      border-radius: 24px;
      margin: 0 -20px;
      width: calc(100% + 40px);
      height: 250px;
    }
  `,
);

export const StyledExamplesContainer = styled('div')(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;

    overflow-x: auto;
    top: 72px;

    box-sizing: border-box;
    width: 100vw;

    justify-content: center;

    ::-webkit-scrollbar {
      display: none;
    }

    ${theme.breakpoints.down('sm')} {
      justify-content: start;
    }

    ${theme.breakpoints.down('md')} {
      top: 40px;
    }
  `,
);

export const ExampleCard = styled(Stack)(
  ({ theme }) => css`
    width: 300px;
    height: 460px;
    padding: 18px;
    border-radius: 18px;
    background: ${theme.palette.primary.light};
    border: 1px solid ${theme.palette.primary.light3};
    flex: 0 0 300px;
    overflow: hidden;

    ${theme.breakpoints.down('md')} {
      width: 204px;
      height: 330px;
      padding: 12px;
      flex: 0 0 204px;
    }
  `,
);

export const StyledCategoryLabel = styled(CategoryLabel)(
  ({ theme }) => css`
    height: 26px;

    img {
      height: 12px;
      width: 12px;
    }

    ${theme.breakpoints.down('md')} {
      height: 17px;

      h6 {
        font-size: 9px;
      }

      img {
        height: 8px;
        width: 8px;
      }
    }
  `,
);

export const StyledExampleTitle = styled(Typography)(
  ({ theme }) => css`
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    margin-bottom: 14px;

    ${theme.breakpoints.down('md')} {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
    }
  `,
);

export const StyledExample1Image = styled('div')(
  ({ theme }) => css`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-image: url('${example1Img}');
    background-position: center;
    background-size: cover;
    border-radius: 12px;

    ${theme.breakpoints.down('md')} {
      border-radius: 8px;
    }
  `,
);

export const StyledExample2Image = styled('div')(
  ({ theme }) => css`
    width: 100%;
    aspect-ratio: 266 / 162;
    background-image: url('${example2Img}');
    background-position: center;
    background-size: cover;
    border-radius: 12px;

    ${theme.breakpoints.down('md')} {
      border-radius: 8px;
    }
  `,
);

export const StyledExample3Image = styled('div')(
  ({ theme }) => css`
    width: 100%;
    aspect-ratio: 1 / 1;
    background-image: url('${example3Img}');
    background-position: center;
    background-size: cover;
    border-radius: 12px;

    ${theme.breakpoints.down('md')} {
      border-radius: 8px;
    }
  `,
);

export const StyledAttributesContainer = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.primary.light2};
    border-radius: 10px;
    padding: 9px 12px;

    & > div {
      line-height: 13px;
    }

    ${theme.breakpoints.down('md')} {
      border-radius: 8px;
      padding: 6px 8px;

      & > div {
        line-height: 9px;
      }
    }

    .inline-list-ellipse {
      margin: 0 8px;
      width: 4px;
      height: 4px;
    }
  `,
);

export const StyledSubcategory = styled(
  Box,
  preventForwardProps(['active']),
)<{ active?: boolean }>(
  ({ theme, active }) => css`
    border-radius: 6px;
    padding: 8px 11px;

    font-size: 8px;
    font-weight: 700;
    line-height: 11px;

    width: max-content;

    background: ${active ? theme.palette.accent.main : theme.palette.primary.light2};
    color: ${active ? theme.palette.primary.light : theme.palette.primary.dark};

    ${theme.breakpoints.down('md')} {
      border-radius: 4px;
      padding: 5px 7px;

      font-size: 6px;
      line-height: 7px;
    }
  `,
);

export const StyledMasterContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    height: 63px;

    background: ${theme.palette.primary.light2};
    border-radius: 16px;
    padding: 9px;

    svg {
      height: 32px;
      width: 32px;
      flex: 0 0 32px;
    }

    ${theme.breakpoints.down('md')} {
      border-radius: 11px;
      padding: 6px;
      gap: 4px;
      height: 47px;

      svg {
        height: 21px;
        width: 21px;
        flex: 0 0 21px;
      }
    }
  `,
);

export const StyledMaster1Image = styled('div')(
  ({ theme }) => css`
    height: 100%;
    aspect-ratio: 1 / 1;
    background-image: url('${master1}');
    background-position: center;
    background-size: cover;
    border-radius: 9px;

    ${theme.breakpoints.down('md')} {
      border-radius: 6px;
    }
  `,
);

export const StyledMaster2Image = styled('div')(
  ({ theme }) => css`
    height: 100%;
    aspect-ratio: 1 / 1;
    background-image: url('${master2}');
    background-position: center;
    background-size: cover;
    border-radius: 9px;

    ${theme.breakpoints.down('md')} {
      border-radius: 6px;
    }
  `,
);

export const StyledProsContainer = styled(Container)(
  ({ theme }) => css`
    ${theme.breakpoints.down('lg')} {
      overflow-x: scroll;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  `,
);

export const StyledProsGrid = styled(Stack)(
  ({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 75px;
    margin-bottom: 120px;

    & > div {
      padding: 40px;
      border-radius: 16px;
      border: 1px solid ${theme.palette.primary.light3};
      text-align: center;
      aspect-ratio: 1 / 1;
    }

    img {
      height: 160px;
      width: 160px;
    }

    .pros-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    ${theme.breakpoints.down('lg')} {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 40px;


      & > div {
        flex: 0 0 414px;
      }
    }

    ${theme.breakpoints.down('md')} {
      margin-top: 40px;

      & > div {
        aspect-ratio: 2 / 3;
        flex: 0 0 200px;

        padding: 20px;
      }

      img {
        height: 80px;
        width: 80px;
      }
    }
  `,
);

export const StyledCallToActionContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light2};
    border-radius: 16px;
    width: 100%;

    margin: 120px 0;

    ${theme.breakpoints.down('md')} {
      margin: 40px 0 120px;
    }
  `,
);
