import React, { useEffect, useState } from 'react';
import { InputLabel, Select as MuiSelect, SelectProps, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import check from 'assets/icons/check.svg';

import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { StyledFormControl, StyledMenuItem } from './styled';

export type ListItem = {
  id: string;
  localizedName: string;
  icon?: string;
};

type Props = Omit<SelectProps<string>, 'value' | 'onChange'> & {
  value?: ListItem | null;
  itemList: ListItem[];
  // Pass this flag if the ListItem contains the icon prop
  withIcons?: boolean;
  shrink?: boolean;
  disablePortal?: boolean;
  onChange?: (item: ListItem | null) => void;
};

const Select: React.FC<Props> = ({
  value,
  itemList,
  className,
  label,
  error,
  disabled,
  placeholder,
  withIcons = false,
  shrink = true,
  onChange,
  disablePortal = false,
  ...props
}) => {
  const theme = useTheme();

  const [currentValue, setCurrentValue] = useState<ListItem | null>(null);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const selectedItem = itemList.find(item => item.id === e.target.value) || null;

    setCurrentValue(selectedItem);
    if (onChange) {
      onChange(selectedItem);
    }
  };

  useEffect(() => {
    setCurrentValue(value || null);
  }, [value]);

  return (
    <StyledFormControl fullWidth error={error} className={className} disabled={disabled} withIcons={withIcons}>
      <InputLabel id="select-label" shrink={shrink || undefined}>
        {label}
      </InputLabel>
      <MuiSelect
        value={currentValue?.id || ''}
        labelId="select-label"
        label={label}
        IconComponent={ExpandMoreIcon}
        displayEmpty
        startAdornment={
          currentValue?.icon && (
            <img width="24px" height="24px" src={currentValue.icon} alt={currentValue.localizedName} />
          )
        }
        renderValue={() =>
          currentValue?.localizedName || (
            <Typography variant="subtitle2" color="primary.dark3">
              {placeholder}
            </Typography>
          ) ||
          label
        }
        disabled={disabled}
        notched={shrink || undefined}
        MenuProps={{
          disableScrollLock: true,
          disablePortal,
          PaperProps: {
            sx: {
              '& .MuiList-root': {
                padding: '10px 0',
              },
              '& .MuiMenuItem-root': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '18px',
                padding: '10 20px',
                background: 'none !important',

                '&:hover': {
                  cursor: 'pointer',
                  background: 'none !important',
                  color: theme.palette.accent.light,
                },

                '&:active': {
                  color: theme.palette.accent.dark,
                  background: 'none !important',
                },
              },
              '& .Mui-selected': {
                color: theme.palette.accent.main,
                background: 'none !important',
                position: 'relative',
                '&:after': {
                  content: '""',
                  display: 'block',
                  background: `url("${check}") no-repeat`,
                  width: '20px',
                  height: '20px',
                  position: 'absolute',
                  right: '20px',
                },
              },
              maxHeight: 330,
              borderRadius: '12px',
              background: theme.palette.background.default,
              border: `1px solid ${theme.palette.accent.main}`,
              boxShadow: 'none',
              margin: '8px 0 !important',
            },
          },
        }}
        onChange={handleChange}
        {...props}
      >
        {itemList.map(item => (
          <StyledMenuItem key={item.id} value={item.id} disableRipple>
            {item.icon && <img width="24px" height="24px" src={item.icon} alt={item.localizedName} />}
            {item.localizedName}
          </StyledMenuItem>
        ))}
      </MuiSelect>
    </StyledFormControl>
  );
};

export default React.memo(Select);

export { SelectSkeleton } from './SelectSkeleton';
