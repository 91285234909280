import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Stack, useTheme } from '@mui/material';
import Modal from '../../@shared/Modal';
import { useTranslation } from 'react-i18next';
import { StyledOTPInput } from './styled';
import Button from 'components/@shared/Button';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (code: string) => Promise<boolean>;
  sendCode: () => Promise<boolean>;
};

const RESEND_OTP_TIMER = 30;

const OPT_INITIAL_STATE = ['', '', '', ''];

const OTPVerificationModal: React.FC<Props> = ({ open, sendCode, onClose, onSubmit }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string[]>(OPT_INITIAL_STATE);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(RESEND_OTP_TIMER);

  const handleInputChange = (value: string, index: number) => {
    if (isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field
    if (value && index < 3) {
      (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
    }
  };

  const handleResend = () => {
    setResendTimer(RESEND_OTP_TIMER);
    sendCode();
  };

  const handleSubmit = async (code: string) => {
    setLoading(true);

    const result = await onSubmit(code);

    if (result) {
      handleClose();
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const handleClose = () => {
    onClose();
    setOtp(OPT_INITIAL_STATE);
    setError(false);
    setLoading(false);
  };

  useEffect(() => {
    const code = otp.join('');
    if (code.length === 4) {
      handleSubmit(code);
    }
  }, [otp]);

  useEffect(() => {
    if (open) {
      setResendTimer(RESEND_OTP_TIMER);
    }
  }, [open]);

  useEffect(() => {
    if (resendTimer === 0) return;

    const id = setTimeout(() => {
      setResendTimer(prev => prev - 1);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [resendTimer]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Stack gap="16px">
        <Typography variant="h6" textAlign="start">
          {t('phoneNumberVerification.verificationTitle')}
        </Typography>
        <Typography variant="body2" textAlign="start">
          {t('phoneNumberVerification.verificationSubtitle')}
        </Typography>

        <Stack direction="row" gap="8px">
          {otp.map((value, index) => (
            <StyledOTPInput
              key={index}
              error={error}
              id={`otp-input-${index}`}
              value={value}
              onChange={e => handleInputChange(e.target.value, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              inputProps={{ maxLength: 1 }}
              variant="outlined"
            />
          ))}
        </Stack>

        {error && (
          <Typography variant="subtitle3" color={theme.palette.error.main} fontWeight="400">
            {t('phoneNumberVerification.errorText')}
          </Typography>
        )}

        <Stack height="22px" alignItems="center">
          {loading ? (
            <Stack direction="row" gap="8px">
              <CircularProgress size={22} color="info" />
              <Typography variant="body1">{t('phoneNumberVerification.loadingStateMessage')}</Typography>
            </Stack>
          ) : (
            <Button variant="text" color="info" disabled={resendTimer > 0} onClick={handleResend}>
              {resendTimer > 0
                ? t('phoneNumberVerification.resendCodeText', { count: resendTimer })
                : t('phoneNumberVerification.resendCodeButtonLabel')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default OTPVerificationModal;
