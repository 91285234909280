import React, { useState } from 'react';
import TitleSection from './TitleSection';
import ServicesExamplesSection from './ServicesExamplesSection';
import ProsSection from './ProsSection';
import CallToActionSection from './CallToActionSection';
import AuthModal from 'components/@common/AuthModal';
import { AccountTypeEnum } from 'constatns';

const ForCompaniesPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState<AccountTypeEnum | null>(null);

  const handleRegister = (accountType: AccountTypeEnum) => {
    setAccountType(accountType);
    setOpen(true);
  };

  return (
    <>
      <TitleSection onRegister={handleRegister} />
      <ServicesExamplesSection />
      <ProsSection />
      <CallToActionSection onRegister={handleRegister} />
      <AuthModal open={open} accountType={accountType} onClose={() => setOpen(false)} />
    </>
  );
};

export default ForCompaniesPage;
