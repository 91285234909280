import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';

import Modal from 'components/@shared/Modal';
import Button from 'components/@shared/Button';
import PhoneInput from 'components/@shared/PhoneInput';
import OTPVerification from 'components/@common/PhoneNumberVerification/OTPVerificationModal';

import { StyledButton, StyledInlineButton } from './styled';
import { BookServiceModalStep } from '../types';

type Props = {
  step: BookServiceModalStep | null;
  loading: boolean;
  onClose: () => void;
  onLoginWithGoogle: () => void;
  onSubmit: (phone: string) => void;
  onSendCode: (phone: string) => Promise<boolean>;
  onValidate: (code: string) => Promise<boolean>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BookServiceModal: React.FC<Props> = ({
  step,
  loading,
  onLoginWithGoogle,
  onClose,
  onSubmit,
  onSendCode,
  onValidate,
}) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState('');

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {step !== BookServiceModalStep.PhoneVerification && (
        <Modal open={!!step} onClose={onClose} maxWidth="400px" width="calc(100% - 40px)">
          <Stack gap="20px">
            <Typography variant={upMd ? 'h3' : 'h6'} color="primary.main">
              {t('bookServiceModal.title')}
            </Typography>

            {step === BookServiceModalStep.Login && (
              <Stack height="232px" justifyContent="space-between">
                <Stack gap="16px">
                  <Typography variant="body2">{t('bookServiceModal.subtitle')}</Typography>

                  <StyledButton
                    startIcon={<GoogleIcon />}
                    typographyProps={{ variant: 'subtitle1' }}
                    onClick={onLoginWithGoogle}
                  >
                    {t('authenticateModal.button')}
                  </StyledButton>
                </Stack>

                <Typography variant="body2" textAlign="center">
                  {t('bookServiceModal.termsAndConditionsDescription')}{' '}
                  <StyledInlineButton typographyProps={{ variant: 'subtitle2', color: 'accent.main' }} variant="text">
                    {t('bookServiceModal.termsAndConditionsButtonLabel')}
                  </StyledInlineButton>
                </Typography>
              </Stack>
            )}

            {step === BookServiceModalStep.Phone && (
              <Stack gap="20px">
                <Typography variant="body2">{t('bookServiceModal.phoneSubtitle')}</Typography>

                <PhoneInput
                  name="phoneNumber"
                  label={t('bookServiceModal.phoneNumberLabel')}
                  onChange={value => setPhone(value)}
                />

                <Button
                  variant="contained"
                  typographyProps={{ variant: 'subtitle2' }}
                  onClick={() => onSubmit(phone)}
                  disabled={!phone}
                >
                  {t('bookServiceModal.submitButtonLAbel')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Modal>
      )}
      <OTPVerification
        open={step === BookServiceModalStep.PhoneVerification}
        onClose={onClose}
        onSubmit={onValidate}
        sendCode={async () => onSendCode(phone)}
      />
    </>
  );
};

export default BookServiceModal;
