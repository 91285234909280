import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';
import { useEffect, useState } from 'react';
import { useAppState } from 'contexts/app';
import useMutation from 'hooks/useMutation';
import { useLazyQuery } from '@apollo/client';
import { AccountTypeEnum } from 'constatns';
import { CREATE_ACCOUNT, GOOGLE_LOGIN } from 'api/requests/auth';
import { GET_CLIENT } from 'api/requests/client';
import { useGoogleLogin } from '@react-oauth/google';
import { setAccount } from 'contexts/app/actions';
import { useClientState } from 'contexts/client';
import { setClient } from 'contexts/client/actions';
import { OutputClientDto } from 'types/client';
import useValidatePhoneNumber from 'hooks/useValidatePhoneNumber';

import { BookServiceModalStep } from './types';

const useBookServiceModal = () => {
  const navigate = useNavigate();

  const {
    state: { account },
    dispatch,
  } = useAppState();

  const {
    state: { client },
    dispatch: dispatchClient,
  } = useClientState();

  const [googleLogin] = useMutation<{ googleLogin: { type: AccountTypeEnum | null } }, { accessToken: string }>(
    GOOGLE_LOGIN,
    {
      context: {
        credentials: 'include',
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createAccount, { loading: createAccountLoading }] = useMutation<
    { createAccount: { id: number } },
    { accountType: AccountTypeEnum }
  >(CREATE_ACCOUNT);

  const [getClient] = useLazyQuery<{ client: OutputClientDto }>(GET_CLIENT, {
    fetchPolicy: 'no-cache',
  });

  const [step, setStep] = useState<BookServiceModalStep | null>(null);
  const [serviceId, setServiceId] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);

  const fetchClient = async () => {
    const { data } = await getClient();
    dispatchClient(setClient(data?.client || null));

    return data?.client || null;
  };

  const onSuccessValidation = (phone: string) => {
    if (!client || !serviceId) return;

    dispatchClient(setClient({ ...client, phone }));

    navigate(getRouteWithParams(PATHS.BOOKING_SERVICE, { serviceId: serviceId.toString() }));
  };

  const { onSendCode, onValidate } = useValidatePhoneNumber(onSuccessValidation);

  useEffect(() => {
    if (!client) {
      fetchClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginWithGoogle = useGoogleLogin({
    scope: 'openid',
    flow: 'auth-code',
    redirect_uri: `${window.location.origin}/api/v1/partners/login/google/callback`,
    onSuccess: async tokenResponse => {
      try {
        const accountResponse = await googleLogin({
          variables: {
            accessToken: tokenResponse.code,
          },
        });

        if (!accountResponse.data) {
          return;
        }

        const type = accountResponse.data?.googleLogin.type;

        if (!type) {
          await createAccount({
            variables: {
              accountType: AccountTypeEnum.Client,
            },
          });

          dispatch(setAccount(AccountTypeEnum.Client));
          setStep(BookServiceModalStep.Phone);
          return;
        }

        dispatch(setAccount(type));

        if (type !== AccountTypeEnum.Client) return;

        const clientResponse = await fetchClient();

        if (!clientResponse?.phone) {
          setStep(BookServiceModalStep.Phone);
          return;
        }

        if (serviceId) {
          navigate(getRouteWithParams(PATHS.BOOKING_SERVICE, { serviceId: serviceId.toString() }));
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const onClose = () => setStep(null);
  const onBook = async (id?: number) => {
    if (!id) return;

    setServiceId(id);

    if (!account) {
      setStep(BookServiceModalStep.Login);
      return;
    }

    if (!client?.phone) {
      const response = await fetchClient();

      if (!response || !response.phone) {
        setStep(BookServiceModalStep.Phone);
        return;
      }
    }

    navigate(getRouteWithParams(PATHS.BOOKING_SERVICE, { serviceId: id.toString() }));
  };

  const onSubmit = async (phone: string) => {
    const codeSent = await onSendCode(phone);
    if (codeSent) {
      setStep(BookServiceModalStep.PhoneVerification);
    }
  };

  return {
    step,
    loading,
    onBook,
    onClose,
    onLoginWithGoogle,
    onSubmit,
    onValidate,
    onSendCode,
  };
};

export default useBookServiceModal;
