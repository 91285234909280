import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Input from 'components/@shared/Input';
import Button from 'components/@shared/Button';
import { useYupRules } from 'hooks/useYupRules';
import useForm from 'hooks/useForm';

import SaveChangesModal from 'components/@common/SaveChangesModal';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';
import PhoneNumberSection from 'components/@common/PhoneNumberVerification';
import useValidatePhoneNumber from 'hooks/useValidatePhoneNumber';

import useInfoTab from './useInfoTab';
import { StyledLeftContainer, StyledMainContainer, StyledInput } from './styled';
import { StyledImg, StyledImgContainer, StyledImgPlaceholder } from '../styled';

export type ClientFormValues = {
  contactName: string;
  phoneNumber: string;
  email: string;
};

const InfoTab: React.FC = () => {
  const { t } = useTranslation();

  const { client, manageClientLoading, onSaveChanges, handleImgInputChange, setClientPhoneNumber } = useInfoTab();

  const validatePhoneNumberProps = useValidatePhoneNumber(setClientPhoneNumber);

  const ref = useRef<HTMLInputElement>(null);

  const imageStatus = useLoadImage(client?.profileImageUri || '');

  const { contactNameRule, requiredStringRule, phoneMetaDataRule, notRequiredStringRule } = useYupRules();

  const initialValues = {
    contactName: client?.contactName || '',
    phoneNumber: client?.phone || '',
    email: client?.email || '',
  };

  const {
    formik,
    formikHelpers: { getFormikProps, withOnChangeStringParameter },
    handleSubmit,
  } = useForm<ClientFormValues>({
    initialValues,
    yupSchema: {
      contactName: contactNameRule,
      phoneNumber: requiredStringRule,
      email: notRequiredStringRule,
    },
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async values => {
      await onSaveChanges(values);
    },
  });

  const isAnyChanges = () => {
    return initialValues.contactName !== formik.values.contactName || initialValues.email !== formik.values.email;
  };

  const handleDiscardChanges = () => {
    formik.resetForm();
  };

  const handleAddImgClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <>
      <StyledMainContainer>
        <StyledLeftContainer>
          <ContainerWithBorder gap="24px">
            <Typography variant="subtitle1" color="primary.main">
              {t('clientCabinetPage.infoTitle')}
            </Typography>
            <Stack direction="row" gap="16px">
              <StyledInput type="file" onChange={handleImgInputChange} ref={ref} accept="image/png, image/jpeg" />
              <StyledImgContainer>
                {client?.profileImageUri && imageStatus === ImageStatus.Loaded ? (
                  <StyledImg src={client.profileImageUri} alt="avatar" />
                ) : (
                  <StyledImgPlaceholder />
                )}
              </StyledImgContainer>
              <Stack justifyContent="space-between" gap="16px" flex="1 1 66%">
                <Stack gap="4px">
                  <Typography variant="subtitle2" color="primary.main">
                    {t('clientCabinetPage.avatarTitle')}
                  </Typography>
                  <Typography variant="body3" color="primary.main">
                    {t('clientCabinetPage.avatarDescription')}
                  </Typography>
                </Stack>
                <Button variant="outlined" sx={{ paddingX: '10px !important' }} onClick={handleAddImgClick}>
                  {t(
                    client?.profileImageUri
                      ? 'clientCabinetPage.avatarChangeButtonLabel'
                      : 'clientCabinetPage.avatarAddButtonLabel',
                  )}
                </Button>
              </Stack>
            </Stack>
            <Input
              {...getFormikProps('contactName')}
              label={t('clientCabinetPage.contactNameFieldLabel')}
              placeholder={t('clientCabinetPage.contactNameFieldPlaceholder')}
              error={!!formik.errors.contactName}
              helperText={formik.errors.contactName}
            />
            {formik.values.email && (
              <Input
                {...getFormikProps('email')}
                InputProps={{
                  type: 'email',
                }}
                label={t('clientCabinetPage.emailLabel')}
                placeholder={t('clientCabinetPage.emailPlaceholder')}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                disabled
              />
            )}
            <PhoneNumberSection value={client?.phone} {...validatePhoneNumberProps} error={formik.errors.phoneNumber} />
          </ContainerWithBorder>
        </StyledLeftContainer>
      </StyledMainContainer>
      <SaveChangesModal
        onSaveChanges={handleSubmit}
        onDiscardChanges={handleDiscardChanges}
        open={isAnyChanges() && formik.isValid}
        loading={manageClientLoading}
      />
    </>
  );
};

export default InfoTab;
