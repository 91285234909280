import React from 'react';
import { Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import Container from 'components/@shared/Container';
import { StyledCallToActionContainer } from './styled';
import enScreenExample from 'assets/images/enScreenExample.png';
import ukScreenExample from 'assets/images/ukScreenExample.png';
import { useTranslation } from 'react-i18next';
import Button from 'components/@shared/Button';
import { AccountTypeEnum } from 'constatns';

const getImgSrc = (language: string) => {
  switch (language) {
    case 'en':
      return enScreenExample;
    case 'uk':
      return ukScreenExample;
    default:
      return enScreenExample;
  }
};

const CallToActionSection = ({ onRegister }: { onRegister: (accountType: AccountTypeEnum) => void }) => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StyledCallToActionContainer>
      <Container>
        <Stack direction={isSmallScreen ? 'column' : 'row'} gap="20px">
          <Stack
            flex={isSmallScreen ? 'auto' : '"0 1 628px"'}
            height={isSmallScreen ? 'auto' : '618px'}
            margin={isSmallScreen ? '40px 0 140px' : '0'}
            justifyContent="center"
            gap={isSmallScreen ? '16px' : '24px'}
          >
            <Typography variant={isSmallScreen ? 'h3' : 'h1'}>{t('forCompaniesPage.callToActionTitle')}</Typography>
            <Stack gap={isSmallScreen ? '16px' : '24px'}>
              <Typography variant={isSmallScreen ? 'body2' : 'body1'}>
                {t('forCompaniesPage.callToActionDescription')}
              </Typography>
              <Stack direction={isMediumScreen ? 'column' : 'row'} gap={isSmallScreen ? '8px' : '16px'}>
                <Button fullWidth variant="contained" onClick={() => onRegister(AccountTypeEnum.Company)}>
                  {t('forCompaniesPage.registerAsCompanyButtonLabel')}
                </Button>
                <Button fullWidth variant="outlined" onClick={() => onRegister(AccountTypeEnum.Company)}>
                  {t('forCompaniesPage.registerAsMasterButtonLabel')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack flex={isSmallScreen ? 'auto' : '0 0 576px'} position="relative">
            <Stack width="100%" margin="-100px 0">
              <img width="100%" src={getImgSrc(i18n.language)} alt="Screen example" />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </StyledCallToActionContainer>
  );
};

export default CallToActionSection;
