import React from 'react';
import { Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import { StyledProsContainer, StyledProsGrid } from './styled';
import pros1 from 'assets/images/pros1.png';
import pros2 from 'assets/images/pros2.png';
import pros3 from 'assets/images/pros3.png';
import pros4 from 'assets/images/pros4.png';
import pros5 from 'assets/images/pros5.png';
import pros6 from 'assets/images/pros6.png';
import pros7 from 'assets/images/pros7.png';
import { useTranslation } from 'react-i18next';

const ProsSection = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const items = [
    {
      title: t('forCompaniesPage.pros.item1Title'),
      description: t('forCompaniesPage.pros.item1Details'),
      imgSrc: pros1,
    },
    {
      title: t('forCompaniesPage.pros.item2Title'),
      description: t('forCompaniesPage.pros.item2Details'),
      imgSrc: pros2,
    },
    {
      title: t('forCompaniesPage.pros.item3Title'),
      description: t('forCompaniesPage.pros.item3Details'),
      imgSrc: pros3,
    },
    {
      title: t('forCompaniesPage.pros.item4Title'),
      description: t('forCompaniesPage.pros.item4Details'),
      imgSrc: pros4,
    },
    {
      title: t('forCompaniesPage.pros.item5Title'),
      description: t('forCompaniesPage.pros.item5Details'),
      imgSrc: pros5,
    },
    {
      title: t('forCompaniesPage.pros.item6Title'),
      description: t('forCompaniesPage.pros.item6Details'),
      imgSrc: pros6,
    },
    {
      title: t('forCompaniesPage.pros.item7Title'),
      description: t('forCompaniesPage.pros.item7Details'),
      imgSrc: pros7,
    },
  ];

  return (
    <StyledProsContainer>
      <StyledProsGrid>
        <Stack>
          <Typography variant={isSmallScreen ? 'h4' : 'h1'} textAlign="start">
            {t('forCompaniesPage.pros.title')}
          </Typography>
        </Stack>
        {items.map(({ title, description, imgSrc }) => (
          <Stack key={title} className="pros-item" alignItems="start">
            <img src={imgSrc} alt={title} />

            <Stack alignItems="start">
              <Typography textAlign="left" mb="20px" variant={isSmallScreen ? 'subtitle1' : 'h4'}>
                {title}
              </Typography>
              <Typography textAlign="left" mb="20px" variant={isSmallScreen ? 'body3' : 'body1'}>
                {description}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </StyledProsGrid>
    </StyledProsContainer>
  );
};

export default ProsSection;
