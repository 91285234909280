import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DayOfWeekEnum } from 'constatns';
import { CategoryItem } from 'types/service';
import { ListItem } from 'components/@shared/Select';
import { SET_MASTER_PHOTO, SET_MASTER_SLUG, REMOVE_COMPANY_MASTER } from 'api/requests/master';
import { Master, RequiredAttributeGroup } from 'types';
import useFilterCityList from 'hooks/useFilterCityList';
import useMutation from 'hooks/useMutation';
import { PATHS } from 'navigation/constants';
import { useMasterState } from 'contexts/master';
import useCategories from 'hooks/useCategories';

import { mergeRequiredAttributes, setMaster } from 'contexts/master/actions';
import { useLazyQuery } from '@apollo/client';
import { GET_REQUIRED_ATTRIBUTES } from 'api/requests/activity';
import { MasterFormValues } from './index';
import useSwitchActivateMaster from './useSwitchActivateMaster';
import useManageMaster from './useManageMaster';

const useInfoTab = () => {
  const {
    state: { master, requiredAttributes },
    dispatch,
  } = useMasterState();
  const navigate = useNavigate();

  const { filterCityList, filterCityListLoading, searchCity } = useFilterCityList();

  const [citySearchQuery, setCitySearchQuery] = useState('');

  const [setMasterSlug] = useMutation<
    { setMasterSlug: Master },
    {
      slug: string;
    }
  >(SET_MASTER_SLUG, { withErrorBanner: false });

  const [selectedCategory, setSelectedCategory] = useState<CategoryItem | null>(null);
  const [selectedAttributeGroups, setSelectedAttributeGroups] = useState<
    Record<string, { id: string; localizedName: string } | null>
  >({});

  const [getRequiredAttributes] = useLazyQuery<
    { requiredAttributes: RequiredAttributeGroup[] },
    { categoryId: string }
  >(GET_REQUIRED_ATTRIBUTES);

  const [setMasterPhoto, { loading: setMasterPhotoLoading }] = useMutation<
    { setMasterPhoto: Master },
    {
      masterId?: number;
      photo: File;
    }
  >(SET_MASTER_PHOTO);
  const [removeCompanyMaster] = useMutation<void, { masterId?: number }>(REMOVE_COMPANY_MASTER);
  const { onSwitchActivateMaster, switchActivateMasterLoading } = useSwitchActivateMaster();
  const { manageMaster, loading: manageMasterLoading } = useManageMaster();

  useEffect(() => {
    searchCity(citySearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySearchQuery]);

  useEffect(() => {
    if (master.town?.localizedName) {
      setCitySearchQuery(master.town?.localizedName);
    }
  }, [master.town?.localizedName]);

  const categories = useCategories();
  const onCitySelectInputChange = useCallback((value: string) => {
    setCitySearchQuery(value);
  }, []);

  const onSaveChanges = (masterFormValues: MasterFormValues) => manageMaster({ masterFormValues });

  const onCategoryChange = async (item: CategoryItem | null) => {
    setSelectedCategory(item);
    setSelectedAttributeGroups({});
    if (item && !requiredAttributes[item.id]) {
      const { data } = await getRequiredAttributes({ variables: { categoryId: item.id } });
      if (data?.requiredAttributes) {
        dispatch(mergeRequiredAttributes({ [item.id]: data.requiredAttributes }));
      }
    }
  };

  const onAttributeChange = (item: ListItem | null, groupId: string) =>
    setSelectedAttributeGroups(prev => ({ ...prev, [groupId]: item }));

  const onAddExperienceClick = () =>
    manageMaster({
      categories: [
        ...master.categories,
        {
          id: selectedCategory?.id!,
          attributeGroups: Object.entries(selectedAttributeGroups).map(([key, value]) => ({
            id: key,
            attribute: { id: value?.id! },
          })),
        },
      ],
    });

  const onDeleteExperienceClick = async (id: string) =>
    manageMaster({
      categories: master.categories.filter(i => i.localId !== id),
    });

  const onDeleteCategoryClick = async (id: string | number) =>
    manageMaster({
      categories: master.categories.filter(i => i.id !== id),
    });

  const onChangeScheduleDay = (day: DayOfWeekEnum, daySchedule: { from: string; to: string } | undefined) =>
    manageMaster({
      schedule: {
        ...master.schedule,
        [day]: daySchedule,
      },
    });

  const onDeleteSchedule = () =>
    manageMaster({
      schedule: Object.values(DayOfWeekEnum).reduce((acc, i) => ({ ...acc, [i]: null }), {}),
    });

  const handleImgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file === undefined) return;
    setMasterPhoto({ variables: { masterId: master.id, photo: file } })
      .then(res => {
        if (res.data) {
          dispatch(setMaster(res.data.setMasterPhoto));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDeleteMaster = async () => {
    await removeCompanyMaster({ variables: { masterId: master.id } });
    navigate(PATHS.COMPANY_CABINET_MASTERS_TAB);
  };

  const handleSlugUpdate = async (slug: string) => {
    const response = await setMasterSlug({ variables: { slug } });
    if (response.data?.setMasterSlug) {
      dispatch(setMaster(response.data.setMasterSlug));
    }
  };

  const setMasterPhoneNumber = (phone: string) => {
    dispatch(setMaster({ ...master, phone }));
  };

  return {
    master,
    cityList: filterCityList,
    cityListLoading: filterCityListLoading,
    categoryList: Object.values(categories),
    selectedCategory,
    requiredAttributes,
    selectedAttributeGroups,
    citySelectInputValue: citySearchQuery,
    switchActivateMasterLoading,
    manageMasterLoading,
    onCategoryChange,
    onAttributeChange,
    onAddExperienceClick,
    onDeleteExperienceClick,
    onDeleteCategoryClick,
    onCitySelectInputChange,
    onSwitchActivateMaster,
    onSaveChanges,
    onChangeScheduleDay,
    onDeleteSchedule,
    handleImgInputChange,
    setMasterPhotoLoading,
    handleDeleteMaster,
    handleSlugUpdate,
    setMasterPhoneNumber,
  };
};

export default useInfoTab;
